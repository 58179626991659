import { useSelector } from "react-redux";
import { auth_token, BASE_URL } from "../services/config";
import { useFetchData } from "./usefetch";

const useFetchCommon = (
  page,
  pageSize,
  nPageSize,
  setInnerData,
  setComponentData,
  setListData,
  setLoading,
  toast,
  setPage,
  setHasMore
) => {
  const regionsData = useSelector((state) => state.region.regionsData);
  const accessToken = useSelector((state) => state.accesstoken_data.accessToken);

  const currentPath = window.location.pathname.replace("/", "") || "home";

  // Function to dynamically build API URL
  const buildApiUrl = (endpoint, item, params) => {
    const queryString = new URLSearchParams(params).toString();
    return `${BASE_URL}${endpoint}/${item}.gzip?${queryString}`;
  };

  

  const apiParams = {
    item_language: "eng",
    region: regionsData?.country_code2,
    auth_token,
    access_token: accessToken,
    page,
    page_size: pageSize,
    npage_size: nPageSize,
  };

  const API_URL = buildApiUrl("catalog_lists", currentPath, apiParams);

  return useFetchData(API_URL, setInnerData, setComponentData, setListData, setLoading, toast, setPage, setHasMore);
};

export default useFetchCommon;
