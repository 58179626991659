import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./Register.css";
import { Link } from "react-router-dom";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";

import {
  USERS_ENDPOINT,
  auth_token,
} from "../../services/config";
import InputField from "../../Componets/inputfield";
import { FaTimes } from "react-icons/fa";
import EmailValidation from "../emailValid/emailValidation";
import { useDispatch, useSelector } from "react-redux";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { setEmailSignupData } from "../../Redux/Slice/signupDetailsSlice";
import { setModalClose } from "../../Redux/Slice/loginModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";

const Register = ({
  handleLogin,
}) => {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);

  const { showRegisterModal } = useSelector(
    (state) => state.registerModal
  );

  const logo = "/images/win-logo.png";
  const LoginPoster = "/images/loginposter.jpg";
  const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";

  const handleMobileLogin = () => {
    dispatch(setModalClose())
    dispatch(setShowRegisterModal({ showRegisterModal: false }))
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: true }))

  };
  const [loading, setLoading] = useState(false);

  const regionsData = useSelector((state) => state.region.regionsData);
  const dispatch = useDispatch();
  const initialFormData = {
    firstname: "",
    email_id: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const [showEmailValidpopup, setShowEmailValidpopup] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [formDataErrors, setFormDataErrors] = useState({
    firstname: "",
    email_id: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  });
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  const loginStatus = localStorage.getItem("login_status")
  const [formValid, setFormValid] = useState(false);

    const activeCountryCode =
      regionsData?.country_code2 !== "IN" ? "email" : "mobile"; 
    const [activeTab, setActiveTab] = useState(
      regionsData?.country_code2 !== "IN" ? "email" : "mobile"
    );
  
    useEffect(() => {
      setActiveTab(regionsData?.country_code2 !== "IN" ? "email" : "mobile");
    }, [activeCountryCode]);
    
    // const firebaseAnalytics = getAnalytics();
  
  
    const handleTabChange = (tab) => {
      setActiveTab(tab);
      setFormData({
        user_id: "",
        password: "",
      });
      setFormDataErrors({
        user_id: "",
        password: "",
      });
    };


  // const firebaseAnalytics = getAnalytics();

  const handleEmailPopupClose = () => {
    setShowEmailValidpopup(false);
  };

  // const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  function isValidEmail (email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email)
};

  function isValidPassword(password) {
    const regex =
      /^(?=.*[a-zA-z])(?=.*[$@!%*^#&\d])(?!.*[\^]{8,})(?!.*[_])[\w@$!%*^#&]{8,}$/;

    return regex.test(password);
  }

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      if (name === "firstname") {
        if (!/^[A-Za-z. ]+$/.test(value)) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "Invalid input",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "",
          }));
          setFormValid(true);
        }
      }

      if (name === "email_id") {
        if (!isValidEmail(value)) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            email_id: "Invalid email address",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            email_id: "",
          }));
          setFormValid(true);
        }
      }

      if (name === "password") {
        setFormDataErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));

        if (!isValidPassword(value)) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password:
              "Password must be 8 characters or longer and include at least one number or symbol.",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
          setFormValid(true);
        }
      }

      if (name === "confirmPassword") {
        if (value !== formData.password) {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "Password and confirm password do not match",
          }));
          setFormValid(false);
        } else {
          setFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "",
          }));
          setFormValid(true);
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleMobileInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === "user_id") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
  
      const errors = {};
  
      if (!/^\d+$/.test(value)) {
        // Check if there are non-numeric characters
        errors.user_id = "Mobile number should contain only numbers";
      } else if (limitedValue.length !== 10) {
        errors.user_id = "Mobile number must be 10 digits";
      } else {
        errors.user_id = "";
      }
  
      setFormDataErrors(errors);
  
      const isFormValid = Object.values(errors).every((error) => !error);
      setFormValid(isFormValid);
    } else {
      // For other fields, simply update the form data
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };


    const handleEmailRegister = () => {
      dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
      dispatch(setModalClose())
      dispatch(setShowRegisterModal({ showRegisterModal: true }))
    };

  const handleRegisterClose = () => {
    dispatch(setShowRegisterModal({ showRegisterModal: false }))
    setFormData({
      user_id: "",
      password: "",
    });

    setFormDataErrors({
      user_id: "",
      password: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!formData.firstname) {
      errors.firstname = "Please select firstname";
    }

    if (!formData.email_id) {
      errors.email_id = "Please select email";
    }

    if (formData.password !== formData.confirmPassword) {
      setFormDataErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "Password and confirm password do not match",
      }));
      setFormValid(false);
      return;
    } else {
      setFormDataErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: "", // Clear the error when passwords match
      }));
      setFormValid(true);
    }

    if (!isValidPassword(formData.password)) {
      setFormDataErrors((prevErrors) => ({
        ...prevErrors,
        password:
          "Password must be 8 characters or longer and include at least one number or symbol.",
      }));
      setFormValid(false);
      return;
    }

    if (Object.keys(errors).length > 0 || !formData.agreeTerms) {
      setFormDataErrors(errors);
      setFormValid(false);
      return;
    } else {
      setFormValid(true);
    }
    setLoading(true)
    try {
      const response = await axios.post(
        USERS_ENDPOINT,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            email_id: formData.email_id,
            firstname: formData.firstname,
            password: formData.password,
            region: regionsData?.country_code2,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
       
        setFormValid(true);
        dispatch(setModalClose());
        dispatch(setShowRegisterModal({ showRegisterModal: false }))
        dispatch(setEmailSignupData({ emailSignupData: response?.data?.data }));
        setShowEmailValidpopup(true);
      } else {
        dispatch(setShowRegisterModal({ showRegisterModal: false }))
      }

      setFormData(initialFormData);
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response);
      }

      if (error?.response?.status === 422) {
        toast.error(error?.response?.data?.error?.message);
          setFormDataErrors({
            ...formDataErrors,
            ["email_id"]: error?.response?.data?.error?.message,
          });
          setShowEmailValidpopup(false);

          if (window && window.sdk && window.sdk.trackEvent) {
            window.sdk.trackEvent("signup_failure", {
              error_type: error?.response?.data?.error?.message,
              method: "email id",
              event_id: 4,
              u_id:
              loginStatus === "true"
              ? getuserid
              : guestuserid,
              platform:"web",
              device_id: "web",
              city: regionsData?.city_name,
              state: regionsData?.state,
              ip: regionsData?.ip,
              postal_code: regionsData?.postal_code,
            });
        
          } else {
            console.error('Analytics SDK is not initialized yet.');
            // Handle initialization state or queue events for later
          }
      
        // logEvent(firebaseAnalytics, "signup_failure", {
        //   method: "email id",
        //   error_type: error?.response?.data?.error?.message,
        //   device_id: "NA",
        // });
        //   firebaseAnalytics.logEvent('signup_failure', {
        //     error_type: error?.response?.data?.error?.message,
        //     method: "email id",
        //     device_id: 'NA'
        // });
        // if (
        //   error?.response?.data?.error?.message === "User is already verified"
        // ) {
        //   toast.error(error?.response?.data?.error?.message);
        //   setFormDataErrors({
        //     ...formDataErrors,
        //     ["email_id"]: error?.response?.data?.error?.message,
        //   });
        //   setShowEmailValidpopup(false);
        // }
        // if (
        //   error?.response?.data?.error?.message ===
        //   "Email Id already registered"
        // ) {
        //   toast.error(error?.response?.data?.error?.message);
        //   setFormDataErrors({
        //     ...formDataErrors,
        //     ["email_id"]: error?.response?.data?.error?.message,
        //   });
        //   setShowEmailValidpopup(false);
        // }
      }
    }finally {
      setLoading(false); 
    }
  };

  const userConfig = useSelector((state) => state.user_config.userConfigData);

  return (
    <>
      {showEmailValidpopup ? (
        <EmailValidation
          setShowEmailValidpopup={setShowEmailValidpopup}
          showEmailValidpopup={showEmailValidpopup}
          handleEmailPopupClose={handleEmailPopupClose}
        />
      ) : (
        <>
          {showRegisterModal && (
            <>
              <Modal
                show={showRegisterModal}
                onHide={handleRegisterClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="border-class"
              >
                <Modal.Body>
                  <div className="card-deck">
                    <Row className="mb-2">
                      <Col lg="12">
                        <div className="login-logo d-flex justify-content-between align-items-center">
                          <div className="">
                            {/* <img src={logo} loading="lazy" alt="logo" /> */}
                          </div>

                          <div className="">
                            <Link onClick={() => dispatch(setShowRegisterModal({ showRegisterModal: false }))}>
                              <FaTimes className="fa-lg" />
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
                      <div className="d-none d-lg-block">
                      <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" /> 
                        </div>
                        <div className="login-left-text d-none">
                          <ul>
                            <li>
                              <p>Lorem Ipsum is simply</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum ***</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum is simply dummy text</p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing{" "}
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing{" "}
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing{" "}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                        <div className="login-left-styles">
                          <h6 className="text-center">Register Now</h6>
                          <p className="text-center">Enjoy ETV Win with More Benefits and Features </p>
                        </div>

                        {regionsData?.country_code2 === "IN" && (
                            <div className="loginTabs">
                              <button
                                className={`btn ${
                                  activeTab === "mobile" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("mobile")}
                              >
                                Mobile Number
                              </button>
                              <button
                                className={`btn ${
                                  activeTab === "email" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("email")}
                              >
                                Email
                              </button>
                            </div>
                          )}
                        {activeTab === "email" &&  <div className="form-style register">
                          <form onSubmit={handleSubmit}>
                            <InputField
                              type="text"
                              name="firstname"
                              value={formData.firstname}
                              onChange={handleInputChange}
                              placeholder="Full Name *"
                              // autoComplete="off"
                              // error={formDataErrors.firstname}
                            />

                            <InputField
                              type="email"
                              name="email_id"
                              value={formData.email_id}
                              // autoComplete="off"
                              onChange={handleInputChange}
                              placeholder="Enter Email ID*"
                              error={formDataErrors.email_id}
                            />

                            <InputField
                              type="password"
                              handleTogglePassword={handleTogglePassword}
                              showPassword={showPassword}
                              name="password"
                              autoComplete="off"
                              value={formData.password}
                              onChange={handleInputChange}
                              placeholder="Password*"
                              error={formDataErrors.password}
                            />

                            <InputField
                              type={showPassword ? "text" : "password"}
                              autoComplete="off"
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleInputChange}
                              placeholder="Confirm Password*"
                              error={formDataErrors.confirmPassword}
                            />
                            <InputField
                              className=""
                              type="checkbox"
                              name="agreeTerms"
                              checked={formData.agreeTerms}
                              onChange={handleInputChange}
                              checkboxLabel="I agree to the <Link to='/termsandconditions'>TERMS OF USE</Link> and <Link to='/privacypolicy'>PRIVACY POLICY</Link>"
                            />
                            <button
                              disabled={!formValid || !formData.agreeTerms}
                              className="next-button-color"
                            >
                              {loading ? <Spinner /> : 'Next'}
                            </button>
                          </form>
                          <div className="registe-color">
                            {regionsData?.country_code2 === "IN" && (
                              <div className="register-left">
                                <p>
                                  Register via &nbsp;
                                  <Link onClick={handleMobileLogin}>
                                    Mobile Number
                                  </Link>
                                </p>
                              </div>
                            )}

                            <div className="register-right">
                              <p>
                                Already a Member?
                                <Link onClick={handleLogin}> Login</Link>
                              </p>
                            </div>
                          </div>
                        </div>}


                          {activeTab === "mobile" && <div className="form-style register">
                                                  <form onSubmit={handleSubmit}>
                                                    <InputField
                                                      type="text"
                                                      name="firstname"
                                                      value={formData.firstname}
                                                      onChange={handleInputChange}
                                                      placeholder="Full Name*"
                                                      autocomplete="off"
                                                    />
                                                    <InputField
                                                      type="tel"
                                                      name="user_id"
                                                      value={formData.user_id}
                                                      onChange={handleMobileInputChange}
                                                      placeholder="Mobile Number*"
                                                      autocomplete="off"
                                                      error={formDataErrors.user_id}
                                                    />
                        
                                                    <InputField
                                                      type="checkbox"
                                                      name="agreeTerms"
                                                      checked={formData.agreeTerms}
                                                      onChange={handleInputChange}
                                                      checkboxLabel="I agree to the <Link to='/termsandconditions'>TERMS OF USE</Link> and <Link className='privacypolicy' to='/privacypolicy'>PRIVACY POLICY</Link>"
                                                      // checkboxLabel="I agree to the TERMS OF USE and PRIVACY POLICY"
                                                    />
                        
                                                    <button disabled={!formValid || !formData.agreeTerms} className="next-button-color"> {loading ? <Spinner /> : 'Next'}</button>
                                                  </form>
                        
                        
                                                 
                                                  <div className="registe-color">
                                                    <div className="register-left">
                                                      {/* <p>
                                                        Register via
                                                        <Link onClick={handleEmailRegister}>
                                                          &nbsp; Email ID
                                                        </Link>
                                                      </p> */}
                                                    </div>
                                                    <div className="register-right">
                                                      <p>
                                                        Already a Member?
                                                        <Link onClick={handleLogin}> Login</Link>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Register;
