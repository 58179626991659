import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, auth_token } from "../../services/config";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { setShowAutopayModal } from "../../Redux/Slice/cancelAutopaySlice";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";

const CancelAutoRenew = () => {
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);
  const cancelAutopayimg = "/images/images/cancelautore.png";
  const regionsData = useSelector((state) => state.region.regionsData);
  const dispatch = useDispatch()
    const { sessionId } = useSelector((state) => state.auth);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab'); 


  const session_id = localStorage.getItem("session_id")
  ? localStorage.getItem("session_id")
  : sessionId;

    const {activePlanData} = useSelector(
        (state) => state.active_plan
      );

      // console.log("activePlanDatasjsjeeesss", activePlanData[0])


  const orderId = activePlanData[0]?.order_id

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const nextApiUrl = `${BASE_URL}user/${session_id}/user_plans/${orderId}/cancel_razorpay_autopay?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
        const response = await axios.get(nextApiUrl, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status === 200) {
          // console.log("autopayrenewdataaaaaa", response)
           dispatch(setShowAutopayModal({ showAutopayModal: false }))
           toast.success(response?.data?.message)
        }
    } catch (error) {
      // console.log("errorshhshshs", error)
        if (error.response.status && error.status === 422) {
            const errorMessage = error.response?.data?.message;
            toast.error(errorMessage)
        }
    }
};


    const { showAutopayModal } = useSelector(
      (state) => state.cancelAutoPay
    );

      const handleAutoPayCancelClose = () => {
        dispatch(setShowAutopayModal({ showAutopayCancelModal: false }))
      };

  return (
    <>
       <Modal
           show={showAutopayModal}
           onHide={handleAutoPayCancelClose}
           aria-labelledby="contained-modal-title-vcenter"
           centered
           size="lg"
           className="border-class cancel-autopay-modal"
         >
           <Modal.Body>
               <Row className="">
               <Col lg="12">
        <div className="d-flex justify-content-end">
          <Link to={(tab === 'info' && "/profile?tab=info") || (tab === 'transactions' && "/profile?tab=transactions") || (tab === 'subscription' && "/profile?tab=subscription")} onClick={handleAutoPayCancelClose} className="close-icons">
            <FaTimes className="fa-lg" />
          </Link>
        </div>
      </Col>
                                </Row>
             <div className="card-deck row just-content">
               <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                 <div className="login-left-styles-autopay">
                   {/* <img src={cancelAutopayimg} alt="" /> */}
                   <h6 className="text-center modal-heading">Do You Want to Cancel Auto Renewal?</h6>
                   <p>Enjoy ETV Win with more benefits and features.</p>
                 </div>
     
                 <div className="form-style">
                   <Row className="">
                     <Col lg={12} md={12} sm={12} xs={12}>
                       <div className="cancelAutopay-button">
                         <Link to='#' onClick={handleSubmit}>Cancel</Link>
                       </div>
                     </Col>

                   </Row>
                 </div>
               </div>
             </div>
           </Modal.Body>
         </Modal>
    </>
  );
};

export default CancelAutoRenew;
