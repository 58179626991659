import React, { useEffect,useRef,useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { access_token, auth_token, BASE_URL } from '../../services/config';
import { getAccessTokenData } from '../../Componets/regions/accessTokenData';
import axios from 'axios';

const Paymentstatus = () => {
  const successicon = "/images/success.png";
  const failedicon = "/images/payment_fail.png";
  const eventTriggered = useRef(false);
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [message1, setMessage1] = useState('');
  const [message2, setMessage2] = useState('');
  const [orderId, setOrderId] = useState('');
  const [subscriptionLocation, setSubscriptionLocation] = useState('');
  const [sourceTitle, setSourceTitle]=useState("");
  const regionsData = useSelector((state) => state.region.regionsData);
  const loginStatus = localStorage.getItem("login_status")
  const userrid = localStorage.getItem("user_id");
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const [userdataMain, setUserdataMain] = useState({
    subscrip: [],
    expiredPlans: [],
    allPlans: [],
  });
  const [subsub, setSubsub] = useState(true);
  const [subsubplans, setSubsubplans] = useState(true);

  const {couponCodedata} = useSelector(state => state.coupon_data)
  const parsedData =  JSON.parse(localStorage.getItem("zdata"))
  // useEffect(() => {
  // localStorage.setItem("zzzzzzzzzz", couponCodedata)
  // }, [couponCodedata])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentGateway = urlParams.get('payment_gateway');
    const paymentStatusParam = urlParams.get('payment_status');
    const msg1 = urlParams.get('message1');
    const msg2 = urlParams.get('message2');
    const order = urlParams.get('order_id');

    setPaymentStatus(paymentStatusParam);
    setMessage1(msg1);
    setMessage2(msg2);
    setOrderId(order);

    const storedSubscriptionLocation = localStorage.getItem('subscriptionLocation');
    setSubscriptionLocation(storedSubscriptionLocation);
    if (paymentGateway === 'billdesk') {
      const param = urlParams.get('msg');
      const res = param.split('|');

      if (res[14] === '0300') {
        handleSuccess(res);
      } else {
        handleFailure(res);
      }
    } else {
      handleMPGSPaymentStatus(paymentStatusParam);
    }
  }, [location.search]);
  const {subscribeSource} = useSelector(state => state.subscribesourcetitle)

  useEffect(() => {
    if(subscribeSource === true){
      const sourcetitles = localStorage.getItem("relatedtitle")
      setSourceTitle(sourcetitles)
  
    }
      }, [subscribeSource])
  const handleSuccess = (res) => {
    // Handle success logic
    // ...

    // console.log('Subscription success:', res);


  //   window.sdk.trackEvent('subscription_success', {
  //     order_id: response.data.data.transaction_id,
  //     source: "before_payment",
  //     payment_mode:paymentgateway.toLowerCase(),
  //     coupon_code: iscouponcode,
  //     coupon_code_type: coupon_code_type,
  //     plan_name: selectedPackageData?.plan_id,
  //     plan_type: selectedPlanData?.period,
  //     pack_value_currency: selectedPlanData.currency,
  //     coupon_code_name: couponCode,
  //     price_charged: totalPayableAmt.toString(),
  //     platform: isMobile ? 'Mobile' : 'Desktop',
  //     transanction_id: "",
  //     t_action: JSON.stringify(response.data.data),
  //     event_id: 14,
  //     // event_time: new Date().toISOString().slice(0, 19),
  // });

  };

  const handleFailure = (res) => {
    // Handle failure logic
    // ...

    // console.log('Subscription failure:', res);
  };

  const handleMPGSPaymentStatus = (status) => {
    // Handle MPGSPaymentStatus logic
    // ...

    // console.log('MPGS Payment Status:', status);
  };



  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : sessionId

  const fetchTrasactions = async () => {
    try {
         // https://stagingott.etvwin.com/users/3ad2d9aab4a362bb72851778b661753e/user_plans.gzip?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&tran_history=true&region=IN

      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans.gzip?auth_token=${auth_token}&access_token=${getAccessTokenData?.access_token || access_token}&tran_history=true&region=${regionsData?.country_code2}`;
      const response = await axios.get(nextApiUrl);

      if (response?.status === 200) {
      //  alert(JSON.stringify(response?.data));
      var responsedata = response.data?.data;
      // console.log("trasaction api"+JSON.stringify(responsedata));
      setUserdataMain({
        subscrip: [...responsedata],
        expiredPlans: [...response.data.expired],
        allPlans: [...responsedata, ...response.data.expired],
      });

      if (response.data.length > 0) {
        setSubsub(false);
        const lastIndex = responsedata.length - 1;
        setUserdataMain((prevData) => ({
          ...prevData,
          subscrip: [
            ...prevData.subscrip.slice(0, lastIndex),
            {
              ...prevData.subscrip[lastIndex],
              valid_till: new Date(prevData.subscrip[lastIndex].valid_till),
            },
          ],
        }));
      }

      setSubsubplans(responsedata.length > 0 || responsedata.expired.length > 0 ? false : true);


       // setTransactionlist(response?.data);
       // setRegionCode(responsedata.country_code2);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    fetchTrasactions();
  }, [paymentStatus]);



  useEffect(() => {
  
    if (paymentStatus === "success" && !eventTriggered.current) {
      const subscription_events = localStorage.getItem("subscription_events");
  
      if (subscription_events) {
        const convertStgtoObject = JSON.parse(subscription_events);
  
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent('subscription_success', {
            order_id: userdataMain.subscrip[0]?.transaction_id,
            country: regionsData?.country_name,
            u_id: loginStatus === "true" ? userrid : guestuserid,
            source: subscribeSource === true ? sourceTitle : "subscription",
            payment_mode: convertStgtoObject.payment_mode,
            coupon_code: parsedData?.coupon_amount || couponCodedata?.coupon_amount,
            coupon_code_type: parsedData?.coupon_type || couponCodedata?.coupon_type,
            plan_name: convertStgtoObject.plan_name,
            plan_type: convertStgtoObject.plan_type,
            pack_value_currency: convertStgtoObject.currency,
            coupon_code_name: parsedData?.coupon_code || couponCodedata?.coupon_code,
            price_charged: convertStgtoObject.price_charged,
            platform: convertStgtoObject.platform,
            transanction_id: userdataMain.subscrip[0]?.transaction_id,
            t_action: convertStgtoObject,
            event_id: 14,
          });
  
          // Mark the event as triggered
          eventTriggered.current = true;
        } else {
          console.error('Analytics SDK is not initialized yet.');
        }
      }
    } else if (paymentStatus !== "success" && !eventTriggered.current) {
      const subscription_events = localStorage.getItem("subscription_events");
  
      if (subscription_events) {
        const convertStgtoObject = JSON.parse(subscription_events);
  
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent('subscription_failure', {
            order_id: userdataMain.subscrip[0]?.transaction_id,
            country: regionsData?.country_name,
            u_id: loginStatus === "true" ? userrid : guestuserid,
            source: subscribeSource === true ? sourceTitle : "subscription",
            payment_mode: convertStgtoObject.payment_mode,
            coupon_code: parsedData?.coupon_amount || couponCodedata?.coupon_amount,
            coupon_code_type: parsedData?.coupon_type || couponCodedata?.coupon_type,
            plan_name: convertStgtoObject.plan_name,
            plan_type: convertStgtoObject.plan_type,
            pack_value_currency: convertStgtoObject.currency,
            coupon_code_name: parsedData?.coupon_code || couponCodedata?.coupon_code,
            price_charged: convertStgtoObject.price_charged,
            subscription_type: convertStgtoObject.subscription_type,
            platform: convertStgtoObject.platform,
            faliure_reason: message1 ? paymentStatus : paymentStatus,
            t_action: convertStgtoObject,
            event_id: 15,
          });
  
          // Mark the event as triggered
          eventTriggered.current = true;
        } else {
          console.error('Analytics SDK is not initialized yet.');
        }
      }
    }
  }, [paymentStatus]);

  return (
    <div className='alitems'>
    <div className='ali-items about-main'>
    <div className="feedback-content feedback-content-center text-center align-content-center">
    <img 
  src={paymentStatus === "success" ? successicon : failedicon} 
  style={paymentStatus === "success" ? {} : { width: "100px", height: "100px" }}
  alt={paymentStatus === "success" ? "payment success" : "payment failed"} 
/>    <h3 className='text-green' >Payment Status : {paymentStatus} </h3>
    <p >{message1}</p>
    <p >{message2}</p>
    <p>Your Order ID: <span className="pay-order-id" style={{color:"#fff8f88c"}}>{orderId}</span></p>
    <p className="payment-note" >Note: <span style={{color:"#fff8f88c"}}>Kindly note down this Order ID for future reference</span></p>
    <div className="button-centred" >
        <a className="btn btn-default form-button full-width-button proceed-button" href="/home" style={{background:"#d41117"}}>PROCEED </a>
    </div>
    </div>
   </div>
   </div>
  )
}

export default Paymentstatus