import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL, access_token, auth_token } from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { setEmailRegisterData } from "../../Redux/Slice/emailregisterSlice";
import { setAuth } from "../../Redux/Slice/authSlice";
import {
  setShowProfile,
  setShowProfileOpen,
} from "../../Redux/Slice/showprofileSlice";
import { setLoginDetails } from "../../Redux/Slice/signinDetailSlice";
export default function EmailVerified() {
  const successemailicon = "/images/email-verified.png";
  const failedemailicon = "/images/email-not-verified.png";

  const dispatch = useDispatch();
  const effectRan = useRef(false)
  const location = useLocation();
  const regionsData = useSelector((state) => state.region.regionsData);
  const [failedMessage, setFailedMessage] = useState("");
  const urlParams = new URLSearchParams(location.search);
  const confirmationToken = urlParams.get("confirmation_token");
  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [verifiedFailed, setVerifiedFailed] = useState(false);
  const [showSubscripeButton, setshowSubscripeButton] = useState(true);
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const getuserid = localStorage.getItem("user_id")
  const loginStatus = localStorage.getItem("login_status")
  const fetchActivePlans = async (sessionID) => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        if (responsedata && responsedata.length > 0) {
          setshowSubscripeButton(false);
        }

        if (responsedata && responsedata.length == 0) {
          setshowSubscripeButton(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
      // toast.error(error?.message);
    } finally {
      const showSubscripeButton = localStorage.getItem("showSubscripeButton");
      if (showSubscripeButton === "true") {
        // window.location.href = '/subscription';
        // window.location.href = '/home';
      } else {
        // window.location.reload();
      }
    }
  };


  useEffect(() => {
    if(effectRan.current === false){
      fetchVerification();
      return () => {
        effectRan.current = true
      }
    }
  }, []);
  
  const fetchVerification = async () => {
    var userregionapi = "";
    try {
      if (regionsData?.country_code2 === "IN") {
        userregionapi = `${BASE_URL}users/verification/${confirmationToken}?auth_token=${auth_token}&access_token=${access_token}`;
      } else if (
        regionsData?.country_code2 !== "IN" &&
        regionsData?.country_code2 !== undefined
      ) {
        userregionapi = `${BASE_URL}users/verification/${confirmationToken}?auth_token=${auth_token}&access_token=${access_token}`;
      } else {
        userregionapi = `${BASE_URL}users/verification/${confirmationToken}?auth_token=${auth_token}&access_token=${access_token}`;
      }

      const nextApiUrl = userregionapi;
      const response = await axios.get(nextApiUrl);
      if (response?.status === 200) {

        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_success", {
            method: "email id",
            u_id:
            loginStatus === "true"
            ? response?.data?.data?.user_id
            : guestuserid,
            // u_id: response?.data?.data?.user_id,
            event_id: 3,
            platform:"web",
            device_id: "web",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

       
        // console.log('emilver', response?.data?.data)
        localStorage.setItem("login_status", true);
        localStorage.setItem("user_id", response?.data?.data?.user_id);
        const newSessionId = response?.data?.data?.session;

        localStorage.setItem("session_id", newSessionId);
        // dispatch(setLoginDetails({ loginDetails: response?.data?.data }));
        localStorage.setItem("loginDetailsdata", JSON.stringify(response?.data?.data))
        setisEmailVerified(true);
        dispatch(
          setEmailRegisterData({ emailRegisterData: response.data.data })
        );

        dispatch(
          setAuth({
            isLoggedIn: true,
            sessionId: response?.data?.data?.session,
          })
        );
        dispatch(setShowProfile({ showAddProfile: true }));

        // await fetchActivePlans(response?.data?.data?.session);
      }
    } catch (error) {
      if (error.response.status === 422) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_failure", {
            method: "email id",
            error_type: error?.response?.data?.error?.message,
            event_id: 4,
            platform:"web",
            u_id:
            loginStatus === "true"
            ? getuserid
            : guestuserid,
            device_id: "web",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

       
        console.error("errrr", error);
        setVerifiedFailed(true);
        setisEmailVerified(false);
        if (
          error.response.data.error.message === "Invalid verification key"
        ) {
          setFailedMessage(error.response.data.error.message);
        }
        if (
          error.response.data.error.message === "User is already verified"
        ) {
          setFailedMessage(error.response.data.error.message);
        }
      }
    }
  };
  return (
    <>
      {verifiedFailed && (
        <Modal
          show={verifiedFailed}
          // onHide={() => setVerifiedFailed(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="row just-content">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="verifiedMessage">
                  <img src={failedemailicon} alt="" />
                  <h6 className="text-center"> Verification Failed </h6>
                  <div className="text-center">
                    <p className="resend-verified-text">
                      {failedMessage ? failedMessage : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isEmailVerified && (
        <Modal
          show={isEmailVerified}
          onHide={() => setisEmailVerified(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="row just-content">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="verifiedMessage">
                  <img src={successemailicon} alt="" />
                  <h6 className="text-center">Verified Successfully!</h6>
                  <div className="text-center">
                    <p className="resend-verified-text">
                      Your Email ID has been verified successfully!
                    </p>
                    <Link
                      to="/"
                      onClick={() => dispatch(setShowProfileOpen())}
                      className="profile-info"
                    >
                      Add Profile Info
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
