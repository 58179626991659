import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showMandatoryFieldsModal: false,
    updateuserdetails: {},
    updateduserdata: false
};

export const showmandatorymodalSlice = createSlice({
  name: "showmandatorymodal",
  initialState,
  reducers: {
    setShowMandatoryFieldsModal: (state, action) => {
        state.showMandatoryFieldsModal = action.payload.showMandatoryFieldsModal  
      },

      setUpdateuserdetails: (state, action) => {
        state.updateuserdetails = action.payload.updateuserdetails  
      },

      setUpdateduserdata: (state, action) => {
        state.updateduserdata = action.payload.updateduserdata  
      },
  },
});

export const {setShowMandatoryFieldsModal, setUpdateuserdetails, setUpdateduserdata} = showmandatorymodalSlice.actions;

export default showmandatorymodalSlice.reducer;