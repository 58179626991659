import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
// import "./MyProfile.css";
import { FaCalendar, FaPen } from "react-icons/fa";
import InputField from "../../Componets/inputfield";
import {
  BASE_URL,
  PROFILE_DETAILS,
  USERS_ENDPOINT,
  access_token,
  auth_token,
} from "../../services/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import RemoveProfilePicture from "../RemoveProfilePicture/removeProfilePicture";
import { setAccountData } from "../../Redux/Slice/accountsDataSlice";
import { setActivePlanData } from "../../Redux/Slice/activePlanSlice";
import { setShowSubscribeButtonStatus } from "../../Redux/Slice/subscribeStatusSlice";
import { setAuth } from "../../Redux/Slice/authSlice";
import profilespinner from "./profilespinner.svg"
import { setIsprofileload } from "../../Redux/Slice/profilespinnerslice";
import { setShowAutopayModal } from "../../Redux/Slice/cancelAutopaySlice";

const MyProfile = () => {
  const { sessionId } = useSelector((state) => state.auth);
  const effectRan = useRef(false)
  const defaulticon = "/images/profile-img.png";
const defaultavatar = "/images/defaultavatar.jpg";
const defaultanime = "/images/defaultanime.png";
  const initialFormData = {
    firstname: "",
    email_id: "",
    mobile_number: "",
    gender: "",
    birthdate: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch()
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const PROFILE_DETAILS = `${BASE_URL}users/${sessionID}/account.gzip?auth_token=${auth_token}&access_token=${access_token}`;
  const [loading, setLoading] = useState(false);
  const [profiledata, setProfiledata] = useState({});
  const [mobileNumber, setmobileNumber] = useState({});
  const [birthdate, setBirthdate] = useState({});
  const [birthdateinput, setBirthdateinput] = useState({});
  const [selectedGenderinput, setselectedGenderinput] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [uploadfilename, setuploadfilename] = useState("");
  // const [activePlanData, setActivePlanData] = useState({});
  const [formData, setFormData] = useState(initialFormData);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [originalDate, setOriginalDate] = useState(null)
  const [profileError, setProfileError] = useState("")
  const datePickerRef = useRef(null);
  const isUserLoggedIn = localStorage.getItem("login_status");
  const [formprofileData, setProfileFormData] = useState({
    profile_pic: null,
  });
  const [profilePicString, setProfilePicString] = useState("");
  const [uploadedImageData, setUploadedImageData] = useState("");

  const [showRemoveProfilePicture, setShowRemoveProfilePicture] =
    useState(false);

  // console.log(profiledata, "profiledata");


const handleAutoRenew = () => {
    dispatch(setShowAutopayModal({ showAutopayModal: true }))
  };

  
  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setBirthdateinput(date);
    setSelectedDate(date);
    let newdate = new Date(date);
    const formattedBirthdate = newdate.toLocaleDateString("en-GB");
    setFormData({ ...formData, birthdate: formattedBirthdate });
  };

  const fetchProfileDetails = async () => {
    try {
      setLoading(true);
      const nextApiUrl = PROFILE_DETAILS;
      const response = await axios.get(nextApiUrl);
      // console.log(`PROFILE_DETAILS`, response.data.data);
      

      if (response?.status === 200) {
        const responsedata = response?.data?.data;
        dispatch(setAccountData({ accountData: response.data.data}));
        setProfilePicString(
          `${responsedata.upload_image.domain_name}/${responsedata.upload_image.key}`
        );
        const formattedMobileNumber = responsedata.mobile_number.replace(
          /^00/,
          ""
        );

        setmobileNumber(formattedMobileNumber);
        setProfiledata(responsedata);

        const binput = responsedata.birthdate || "";
        const selectedGenderinput = responsedata.gender;
        let birthdateInput;
        if (binput === "") {
          let newdate = new Date();
          const formattedBirthdate = newdate.toLocaleDateString("en-GB");
          birthdateInput = formattedBirthdate;
          setBirthdateinput(formattedBirthdate);
          setOriginalDate(newdate); 
          // Set to current date or a default date as needed
        } else {
          let newdate = new Date(binput);
          const formattedBirthdate = newdate.toLocaleDateString("en-GB");
          birthdateInput = formattedBirthdate;
          setBirthdateinput(birthdateInput);
          setOriginalDate(newdate); 

        }
        //alert(JSON.stringify(birthdateInput));
        setSelectedDate(originalDate); 
        // setBirthdateinput(birthdateInput);
        setselectedGenderinput(selectedGenderinput);
        setLoading(false)
      }
    } catch (error) {
      if(error?.status === 422){
        setProfileError(error?.response?.data?.error?.message)
        console.error("error fetching data:", error?.response?.data?.error?.message);
      }
      // toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const regionsData = useSelector((state) => state.region.regionsData);

  const fetchActivePlans = async () => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        //  console.log("subscription_plas_api", subscription_plas_api);
        // console.log(`User Active Plan `, response.data.data);
        dispatch(setActivePlanData({ activePlanData: response.data.data }));
        if (responsedata && responsedata.length > 0) {
          dispatch(setShowSubscribeButtonStatus({ showSubscribeButtonStatus: true }))
          // setactivePlanId(responsedata[0].plan_id);
          localStorage.setItem("showSubscripeButton", false);
          //  alert(nextApiUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
      // toast.error(error?.message);
    } finally {
      // setLoading(false);
    }
  };

  const {activePlanData} = useSelector(
    (state) => state.active_plan
  );

  const activePlansdetails = activePlanData[0]

  // console.log("activePlansdetails", activePlansdetails)
  useEffect(() => {
    if(effectRan.current === false){
      if (sessionID) {
        fetchActivePlans();
        const isUserLoggedIn = localStorage.getItem("login_status");
        dispatch(setAuth(Boolean(isUserLoggedIn)));
      }
      return () => {
        effectRan.current = true
      }
    }
  }, [sessionID]);






  const handleEditProfile = (event) => {
    event.preventDefault();
    setFormData({
      firstname: profiledata.firstname,
      email_id: profiledata.email_id,
      mobile_number: profiledata.mobile_number,
      gender: profiledata.gender,
      birthdate: profiledata.birthdate,
      profile_pic: profiledata.profile_pic,
    });
    // setShowRemoveProfilePicture(true)
    setEditMode(true);
  };
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       setFormData({
  //         ...formprofileData,
  //         profile_pic: reader.result,
  //       });
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      const fileName = file.name;
      const imageFileUrl = `${profilePicString}${fileName}`;
 // console.log(file, "File Data");
 // console.log(imageFileUrl, "imageFileUrl");
      setuploadfilename(fileName);
      reader.onloadend = () => {
        setProfileFormData({
          ...formprofileData,
          profile_pic: reader.result,
        });
        setFormData({ ...formData, profile_pic: imageFileUrl });
        setUploadedImageData(reader.result); // This ensures the preview shows immediately
      };
  
      reader.readAsDataURL(file);
      event.target.value = null;
    }
  };
  
  const dataURItoBlob = (dataURI) => {
    const binary = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
  };
  // const uploadProfilePic = async (file) => {
  //   try {
  //     const awsData = {};

  //     const fd = new FormData();
  //     fd.append("key", awsData.key + file.name);
  //     fd.append("AWSAccessKeyId", awsData.access_key);
  //     fd.append("acl", "public-read");
  //     fd.append("policy", awsData.policy);
  //     fd.append("signature", awsData.signature);
  //     fd.append("Content-Type", "image/jpeg");
  //     fd.append("file", file);

  //     await axios.post("https://etv-win-users.s3.amazonaws.com/", fd, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error uploading profile image:", error);
  //     //toast.error("Failed to upload profile image. Please try again.");
  //   }
  // };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    dispatch(setIsprofileload({isprofileload: true}))
    try {
      const croppedImage = formprofileData.profile_pic; // Replace with your actual Data URI
      const blob = dataURItoBlob(croppedImage);
      const awsData = profiledata.upload_image;
      const imagename = uploadfilename;
      //alert(JSON.stringify(awsData.key + imagename));
      var fd = new FormData();
      // fd.append("key", awsData.key + imagename);
      // fd.append("AWSAccessKeyId", awsData.access_key);
      // fd.append("acl", "public-read");
      // fd.append("policy", awsData.policy);
      // fd.append("signature", awsData.signature);
      // fd.append("Content-Type", "image/jpeg");
      // fd.append("file", blob);

      fd.append("key", awsData.key + imagename)
      fd.append("acl", awsData.acl);
      fd.append('X-Amz-Credential', awsData.credential);
      fd.append('X-Amz-Algorithm', awsData.x_amz_algorithm);
      fd.append('X-Amz-Date', awsData.valid_till);
      fd.append('Policy', awsData.policy); // Example policy
      fd.append('X-Amz-Signature', awsData.signature);
      fd.append("Content-Type", "image/jpeg");
      fd.append("file", blob);
      // console.log(fd, "fd");
      const response = await axios.post(awsData.domain_name, fd, {
        headers: {
          "Content-Type": "multipart/form-data", // Use 'multipart/form-data' for FormData
        },
      });

      if (response?.status === 204) {
        try {
          dispatch(setIsprofileload({isprofileload: false}))
          await axios.put(
            PROFILE_DETAILS,
            {
              firstname: profiledata.firstname,
              email_id: profiledata.email_id,
              mobile_number: profiledata.mobile_number,
              gender: profiledata.gender,
              birthdate: profiledata.birthdate,
              profile_pic: formData.profile_pic,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          toast.success("Profile Picture updated successfully!");
          setEditMode(false);
          setProfileFormData({ ...formprofileData, profile_pic: null });
          await fetchProfileDetails();
        } catch (error) {
          toast.error("Failed to update Profile Picture. Please try again.");
        }
      } else {
        /// toast.error('Failed to update profile. Please try again.');
      }
    } catch (error) {
      console.error("Error updating profile pic:", error);
      if(error?.message === "Network Error"){
        dispatch(setIsprofileload({isprofileload: false}))
      }
      toast.error("Failed to update Profile Picture. Please try again.");
    }
  };

  useEffect(() => {
    fetchProfileDetails()
   
  }, [PROFILE_DETAILS, dispatch]);

  const { accountData } = useSelector(
    (state) => state.account_data
  );

  const profileavatar = localStorage.getItem("profilePicture")
  const profileid = localStorage.getItem("selectedProfileId")
  const profilename = localStorage.getItem("profileName")
  useEffect(() => {
    if (profileError === "Invalid Session Id") {
      window.location.href = "/"
    }
  }, [isUserLoggedIn, profileError, accountData, dispatch]);

  const handleProfileDetailsUpdate = async (e) => {
    const formDataJSON = JSON.stringify(formData);
    const modifiedFormData = {
      firstname:formData.firstname,
      email_id: formData.email_id,
      mobile_number: formData.mobile_number,
      gender: formData.gender,
      birthdate: formData.birthdate,
    };
    e.preventDefault();
    try {
      // if (formData.profile_pic) {
      //   await uploadProfilePic(formData.profile_pic);
      // }
      //alert(JSON.stringify(formData));
      await axios.put(PROFILE_DETAILS, modifiedFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success("Profile Details updated successfully!");
      setEditMode(false);
      fetchProfileDetails();
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (editMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);

  const handleIconClick = () => {
    // Programmatically focus on the DatePicker when the icon is clicked
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  // console.log("formattedDate", formattedDate);

  // console.log(profiledata.profile_pic, "Profile Picture");

  const cancelProfileEdit = () => {
    setSelectedDate(originalDate);
    setEditMode(false);
    setBirthdateinput(originalDate.toLocaleDateString("en-GB")); // Format for display

    setFormData({ ...formData, profile_pic: null });
  };

  const handleRemoveProfilePicModalClose = () => {
    setShowRemoveProfilePicture(false);
  };

  const handleRemoveProfilePic = () => {
    setShowRemoveProfilePicture(true);
  };


  // console.log("accountDatassjs", accountData)
  // console.log("previsiisis", uploadedImageData)

     const {isprofilepicremove} = useSelector((state) => state.profile_pic_remove);
     const {isprofileload} = useSelector((state) => state.profilepic_load);



  return (
    <>
      {/* <Header /> */}
      <RemoveProfilePicture
  showRemoveProfilePicture={showRemoveProfilePicture}
  handleRemoveProfilePicModalClose={handleRemoveProfilePicModalClose}
  setShowRemoveProfilePicture={setShowRemoveProfilePicture}
  profiledata={profiledata}
  fetchProfileDetails={fetchProfileDetails}
  setProfiledata={setProfiledata}
  setUploadedImageData={setUploadedImageData} 
/>

      <div className='main-page'>
        <div className="main background-layer">
          <div className="padding-betw ">
            <div className="profile-main">
              <Container>
                <Row>
                  <Col lg={4}>
                    <div className="top-profile">
                      <div className="">
                        <div className="top-profile-left">
                          <div className="icon-rounded">
                            {/* <label
                              htmlFor="profile_picInput"
                              className="profile-image-label"
                            > */}

{/* <img
  src={
    isprofileload
      ? profilespinner
      : uploadedImageData || 
        (accountData?.profile_pic && accountData.profile_pic !== ""
          ? accountData.profile_pic
          : defaulticon) 
  }
  alt="Profile"
/> */}
{/* <img src={profileavatar || defaultavatar} className="switchprofile" alt="" /> */}

<img
  src={((profileavatar || defaultavatar) == "null" ? defaultanime : (profileavatar || defaultavatar)) || defaultavatar}
  alt=""
  // className="switchprofile"
/>


                              <>
                                {/* <div className="editIcon">
                                  <FaPen />
                                </div> */}
                                {/* <input
                                  id="profile_picInput"
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={(e) => handleImageChange(e)}
                                  // disabled={!editMode}
                                /> */}
                              </>
                            {/* </label> */}
                          </div>
                        </div>
                        {formprofileData.profile_pic && (
                          <div className="upload-button-container d-flex justify-content-center">
                            <button
                              className="editBtn"
                              // style={{ marginLeft: "10px" }}
                              type="submit"
                              onClick={handleProfileUpdate}
                            >
                              Upload
                            </button>
                          </div>
                        )}

                        {/* <S3FileUpload /> */}

                        
                        {editMode ? (profiledata.profile_pic && (
                          <div className="button-container d-flex justify-content-center">
                            <button
                              className="editBtn"
                              type="submit"
                              onClick={() => {
                                handleRemoveProfilePic();
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        )) : ''}

                        <div className="top-profile-right">
                          <h6>{profilename || profiledata.firstname}</h6>
                          {/* <p>Premium Plan expires on 28 Mar 2024</p> */}
                        </div>
                      </div>
                      <hr />
                      <div className="subscription-status mt-4">
                        <span>subscription status </span>
                        <span className="planName">
                          {activePlansdetails?.subscription_title || "free"}
                        </span>

                        {activePlansdetails?.valid_till ? (
                          <p>Expires on {formatDate(activePlansdetails?.valid_till) || ""} </p>
                        ) : (
                          ""
                        )}
                      </div>

                      {accountData?.isAutoPayEnabled && 
<div className="button-container d-flex justify-content-center mt-3">
  <button className="cancel-autopay-button" onClick={handleAutoRenew}>
    Cancel Autopay
  </button>
</div>
              }


                    </div>
                  </Col>
                  <Col lg={8}>
                    <div className="form-style-new my-profiles">
                      <div className="bottom-profile">
                        <div className="personal">
                          <div className="personl-profile-left">
                            <h6>Personal Details</h6>
                          </div>
                        </div>
                      </div>
                      
                      {loading ? (<div className="text-center"><Spinner /></div>) :

                      (profiledata.mobile_number || profiledata.email_id ? (
                        <form>
                          <Row>
                            {/* <Col lg={6} md={6}>
                              <div className="form-group lable-space">
                                <label for="checkbox1">Name</label>
                                {editMode ? (
                                  <InputField
                                    type="text"
                                    name="firstname"
                                    ref={inputRef}
                                    onChange={handleChange}
                                  />
                                ) : (
                                  <InputField
                                    disabled={!editMode}
                                    value={
                                      formData.firstname || profiledata.firstname
                                    }
                                  />
                                )}
                              </div>
                            </Col> */}
                            {(profiledata.email_id || profiledata.payment_email_id) && (
                              <Col lg={6} md={6}>
                                <div className="form-group lable-space">
                                  <label for="checkbox1">Email</label>
                                  <InputField
                                    // disabled={!editMode}
                                    disabled={true}
                                    type="email"
                                    name="email_id"
                                    onChange={handleChange}
                                    value={
                                      formData.email_id || (profiledata.payment_email_id)
                                    }
                                    autocomplete="off"
                                  />
                                </div>
                              </Col>
                            )}

                            {(profiledata.mobile_number || profiledata?.payment_mobile_number) && (
                              <Col lg={6} md={6}>
                                <div className="form-group lable-space">
                                  <label for="checkbox1">Phone Number</label>
                                  <InputField
                                    disabled={(profiledata.mobile_number || profiledata?.payment_mobile_number) ? true : false}
                                    type="tel"
                                    name="mobile_number"
                                    onChange={handleChange}
                                    value={mobileNumber}
                                    autocomplete="off"
                                  />
                                </div>
                              </Col>
                            )}

                            <Col lg={6} md={6}>
                              <div className="form-group lable-space">
                                <label for="checkbox1">DOB</label> <br></br>
                                <div className="profileCalendar">
                                  {editMode ? (
                                    <DatePicker
                                      name="birthdate"
                                      selected={selectedDate}
                                      disabled={!editMode}
                                      onChange={handleDateChange}
                                      dateFormat="dd/MM/yyyy"
                                      value={birthdateinput}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      yearDropdownItemNumber={55}
                                      placeholderText="dd/mm/yyyy"
                                      ref={datePickerRef}
                                      maxDate={new Date()}
                                    />
                                  ) : (
                                    <InputField
                                      disabled={!editMode}
                                      value={birthdateinput}
                                      autocomplete="off"
                                    />
                                  )}

                                  <div className="icon" onClick={handleIconClick}>
                                    <FaCalendar />
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="form-group lable-space">
                                <label for="checkbox1">Gender</label>
                                {editMode ? (
                                  <select
                                    name="gender"
                                    disabled={!editMode}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select</option>
                                    <option
                                      value="Male"
                                      selected={"Male" === selectedGenderinput}
                                    >
                                      Male
                                    </option>
                                    <option
                                      value="Female"
                                      selected={"Female" === selectedGenderinput}
                                    >
                                      Female
                                    </option>
                                    <option
                                      value="Others"
                                      selected={"Others" === selectedGenderinput}
                                    >
                                      Others
                                    </option>
                                  </select>
                                ) : (
                                  <InputField
                                    disabled={!editMode}
                                    value={selectedGenderinput}
                                    autocomplete="off"
                                  />
                                )}
                              </div>
                            </Col>
                            <Col lg={12} md={12}>
                              <div className="personl-profile-right">
                                {editMode ? (
                                  <div className="button-container">
                                    <button
                                      className="red-button"
                                      type="submit"
                                      onClick={handleProfileDetailsUpdate}
                                    >
                                      Submit
                                    </button>
                                    <button
                                      className="cancel-button"
                                      onClick={cancelProfileEdit}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : (
                                  <Link to="/" onClick={handleEditProfile}>
                                    <FaPen /> Edit Profile
                                  </Link>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </form>
                      ) : (
                        <></>
                      ))
                       }
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyProfile;
