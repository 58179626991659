import React, { Suspense, lazy, useEffect, useState } from "react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// import Tvchanels from "../pages/TvChannels/Tvchanels";
import VideoDetails from "../pages/videodetails/videodetails";
// import LiveTv from "../pages/liveTv/LiveTv";
// import News from "../pages/News/News";
import Login from "../pages/login/login";
import LoginScreen from "../pages/login/login";
// import Health from "../pages/Health/Health";
// import Agriculture from "../pages/Agriculture/Agriculture";
import ActiveTv from "../pages/ActiveTv/ActiveTv";
import MyProfile from "../pages/MyProfile/MyProfile";
import MyTransactions from "../pages/MyTransactions/MyTransactions";
// import Movies from "../pages/Movies/Movies";
import Filters from "../pages/Filters/Filters";
import MyPreferences from "../pages/MyPreferences/Preferences";
import EmailValidation from "../pages/emailValid/emailValidation";
import Successpage from "../pages/Register/successpage";
import VerifyFailed from "../pages/Register/failedpage";
import About from "../Cmspages/About/aboutus";
import Faq from "../Cmspages/FAQ/Faq";
import Feedback from "../Cmspages/Feedback/Feedback";
import MoreDetails from "../pages/More/More";
import MorePage from "../pages/MorePage";
import MoreDetailsPage from "../pages/MoreDetails";
import OtpVerification from "../pages/otpverify/signupmobileverify";
import PaymentStatus from "../pages/Paymentstatus/Paymentstatus";
import EmailVerified from "../pages/Register/emailverified";
import ShowVideoView from "../pages/videodetails/showvideoview";
import MyList from "../pages/Mylist/Mylist";
import ResetPassword from "../pages/Register/ResetPassword";
import Movies from "../pages/Movies/Movies";
import PrivacyPolicy from "../Cmspages/PrivacyPolicy/privacypolicy";
import ChangePassword from "../pages/Register/changepassword";
import { NoInternetScreen } from "../pages/nointernet";
import EpisodeMore from "../pages/More/EpisodeMore";
import RedeemScreen from "../pages/redeem";
import AboutUs from "../Cmspages/About/aboutus";
import TermsAndConditions from "../Cmspages/TermsConditions/TermsConditions";
import Header from "../Componets/Header/Header";
import Tvaboutus from "../Otherdevices/tvaboutus";
import Othertermsandconditions from "../Otherdevices/othertermsandconditions";
import OtherFaq from "../Otherdevices/Otherfaq";
import Otherprivacypolicy from "../Otherdevices/Otherprivacypolicy";
import Otherprivacypolicytv from "../Otherdevices/Otherprivacypolicytv";
import Plandetailstv from "../Otherdevices/Plandetailstv";
import Othercontactus from "../Otherdevices/Othercontactus";
import { useSelector } from "react-redux";
import RecipesMoreDetails from "../pages/More/recipesMore";
import axios from "axios";
import { access_token, auth_token, BASE_URL } from "../services/config";
import { Col, Modal, Row } from "react-bootstrap";
import ProfileInfo from "../pages/MyProfile/profileinfo";
import Packdetails from "../Otherdevices/Packdetails";
import ContactUs from "../Cmspages/Contact/contactus";
import ProfileSelector from "../pages/MyProfile/profileselectorui";
import NewProfile from "../pages/MyProfile/newprofile";
import ManageProfiles from "../pages/MyProfile/manageprofile";
import EditProfile from "../pages/MyProfile/editprofile";
import ProtectedRoute from "./protectedroutes";
// import Recipes from "../pages/recipes/recipes";
const LazyLoadHome = lazy(() => import("../pages/Homepage/Home"));
const LazyLoadGames = lazy(() => import("../pages/Homepage/games"));

const LazyLoadTvShows = lazy(() => import("../pages/TvChannels/Tvchanels"));
const LazyLoadMovies = lazy(() => import("../pages/Movies/Movies"));
const LazyLoadLiveTv = lazy(() => import("../pages/liveTv/LiveTv"));
const LazyLoadNews = lazy(() => import("../pages/News/NewsData"));
const LazyLoadRecipes = lazy(() => import("../pages/recipes/recipes"));
const LazyLoadHealth = lazy(() => import("../pages/Health/Health"));
const LazyLoadAgriculture = lazy(() =>
  import("../pages/Agriculture/Agriculture")
);
// const LazyLoadVideoDetails = lazy(() =>
//   import("../pages/videodetails/videodetails")
// );
const LazyLoadSubscription = lazy(() =>
  import("../pages/Subscription-Plans/Subscriptionplan")
);

const Routers = () => {
  const verificationfailed = "/images/img_verification_fail.png";
  const resetpassword = "/images/notfound.png";

  const location = useLocation();
  const navigate = useNavigate();
  const [isValidkey, setIsValidkey] = useState("")
  const [showfailedmessage, setShowfailedmessage] = useState(false);
  const [keyerror, setKeyError] = useState("")
  const queryParams = new URLSearchParams(location.search);
  const secretKey = queryParams.get('forgot_pin_secret_key');
  const [sessionId, setSessionId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [foodfiltershow, setFoodfiltershow] = useState(false);

  const loginstatus = localStorage.getItem("login_status");
  const [hideHeader, setHideHeader] = useState(false); // State to control header visibility

const isVerifiedURL = `${BASE_URL}/users/verify_reset_password_key?key=${secretKey}&auth_token=${auth_token}&access_token=${access_token}`

useEffect(() => {
const verifyPasswordResetKey = async () => {
  try {
    const response = await axios.get(isVerifiedURL);
    if(response?.status === 200){
      setIsValidkey(response?.data?.data?.message)
    }
  // console.log('aanannanna', response)
  } catch (error) {
    if(error?.response?.status === 422){
      navigate('/')
      setKeyError(error?.response?.data?.error.message)
      setShowfailedmessage(true)
    }
    console.log("errorssss", error)
  } finally {
  }
};

if(secretKey){
  verifyPasswordResetKey();   
}

}, [secretKey]);

const handleerrorModalClose = () => {
  setShowfailedmessage(false)
  navigate("/home")
}
useEffect(() => {
  // Check if the path contains double slashes
  if (location.pathname.includes("//")) {
    // Remove the double slashes
    const correctedPath = location.pathname.replace("//", "/");
    // Retain search parameters
    const searchParams = location.search;
    // Navigate to the corrected path with the existing search parameters
    navigate(`${correctedPath}${searchParams}`, { replace: true });
  }
}, [location, navigate]);

useEffect(() => {
  // Decode the pathname to check for any special characters properly
  const decodedPath = decodeURIComponent(location.pathname);
  const zeroWidthSpace = '\u200B'; // Unicode for zero-width space

  // Check if the decoded pathname contains a zero-width space or other unwanted characters
  if (decodedPath.includes(zeroWidthSpace)) {
    // Replace the zero-width space with an empty string
    const sanitizedPath = decodedPath.replace(new RegExp(zeroWidthSpace, 'g'), '');

    // Navigate to the sanitized path with the existing search parameters
    navigate(`${sanitizedPath}${location.search}`, { replace: true });
  }
}, [location, navigate]);


const shouldHideHeader = (pathname) => {
  // Normalize the pathname by replacing double slashes with a single slash
  const normalizedPathname = pathname.replace(/\/\//g, '/');

  // Define the list of routes where you want to hide the header
  const routesToHideHeader = [
    // "/contactus.html",
    // "/privacypolicy.html",
    "/packdetails.html",
    // "/faq.html",
    // "/aboutus.html",
    // "/termsandconditions.html",
    // Add more routes as needed
  ];

  // Check if the current normalized pathname matches any route where the header should be hidden
  return routesToHideHeader.includes(normalizedPathname);
};

useEffect(() => {
  // Check if the header should be hidden for the current route
  const pathname = window.location.pathname;
  setHideHeader(shouldHideHeader(pathname));
}, []);

  // const getActivePlanData = JSON.parse(localStorage.getItem("useractiveplan"))
  // const regionsData = useSelector((state) => state.region.regionsData);

  // console.log("regionsDatass", regionsData?.country_code2)


  // console.log("getActivePlanDatajsjsj", getActivePlanData)
  // console.log("userrrrid", getActivePlanData?.plan_id);
  
  

  const { menuListData } = useSelector((state) => state.menu_list);

  // console.log("menuListData", menuListData)

  const requireAuth = (element) => {
    return loginstatus === "true" ? element : <Navigate to="/home" replace />;
  };

  // console.log("sessionId from routers", sessionId);
  //   const currentRoute = location.pathname.includes('/recipes') ? '/recipes' : '';
  //  console.log('currentRoute:', currentRoute);
  const predefinedRoutes = [
    // { path: "/tv-shows", element: <LazyLoadTvShows /> },
    { path: "/home", element: <LazyLoadHome /> },
    { path: "/games", element: <LazyLoadGames /> },
    { path: "/recipes", element: <LazyLoadRecipes /> },
    { path: "/live", element: <LazyLoadLiveTv /> },
    { path: "/news", element: <LazyLoadNews /> },
    {
      path: "/login",
      element: <Login />,
    },
    { path: "/subscription-plans", element: <LazyLoadSubscription /> },
    { path: "/health", element: <LazyLoadHealth /> },
    { path: "/agriculture-all", element: <LazyLoadAgriculture /> },
    // { path: "/mytransactions", element: <MyTransactions /> },
    { path: "/telugu-movies", element: <LazyLoadMovies /> },
    { path: "/Filters", element: <Filters /> },
    // { path: "/changepassword", element: <ChangePassword /> },
    { path: "/emailvalid/:confirmationToken", element: <EmailValidation /> },
    // { path: "/More", element: <More /> },
    { path: "/aboutus", element: <AboutUs /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/faq", element: <Faq /> },
    { path: "/feedback", element: <Feedback /> },
    { path: "/privacypolicy", element: <PrivacyPolicy /> },
    { path: "/termsandconditions", element: <TermsAndConditions /> },
    // { path: "/error", element: <NoInternetScreen /> },
    { path: "/error", element: <NoInternetScreen /> },
    { path: "/redeem", element: <RedeemScreen /> },

    // { path: "/aboutus.html", element: <Tvaboutus /> },
    // { path: "/termsandconditions.html", element: <Othertermsandconditions /> },
    // {
    //   path: "/termsandconditions-tv.html",
    //   element: <Othertermsandconditions />,
    // },
    // { path: "/faq.html", element: <OtherFaq /> },
    // { path: "/privacypolicy.html", element: <Otherprivacypolicy /> },
    // { path: "/privacypolicy-tv.html", element: <Otherprivacypolicytv /> },
    // { path: "/plandetailstv.html", element: <Plandetailstv /> },
    // { path: "/contactus.html", element: <Othercontactus /> },
    { path: "/packdetails.html", element: <Packdetails /> },

    // { path: "/Paymentstatus", element:<Paymentstatus/> }
    // termsandconditions
  ];

  // const userConfig = useSelector((state) => state.user_config.userConfigData);

  // const webPortalURL = userConfig?.params_hash2?.config_params?.web_portal_url

  // console.log("userConfig", userConfig?.params_hash2?.config_params?.web_portal_url)

  // const queryParams = new URLSearchParams(window.location.search);
  // const authToken = queryParams.get('authToken');
  // const region = queryParams.get('region');
  // const planId = queryParams.get('plan_id');
  // const productId = queryParams.get('id');
  // const platformPrice = queryParams.get('platform');
  // const showDate = queryParams.get('show_date');
  // const date = queryParams.get('date');

  // console.log("skskskskks", authToken)
  // console.log("regionss", region)
  // console.log("planIdss", planId)

//   useEffect(() => {
// if(authToken && region && planId && productId){
//   navigate(webPortalURL + "/packdetails.html")
// }
//   }, [])




  return (
    <div>
      <Suspense fallback="">
        {!hideHeader && <Header />}

        <Routes>
          {predefinedRoutes?.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}

          {menuListData?.map((item) => (
            <Route
              key={item.friendly_id}
              path={`/${item.friendly_id}`}
              element={<LazyLoadTvShows key={item.friendly_id} />}
            />
          ))}
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/" />} />
          {/* <Route
            path="/changepassword"
            element={requireAuth(<ChangePassword />)}
          /> */}
          <Route
         exact
         path="/profile"
         element={
           <ProtectedRoute>
             <ProfileInfo />
           </ProtectedRoute>
         }
       />

          {/* <Route path="/profile" element={<ProfileInfo />} /> */}
          {/* <Route path="/preference" element={requireAuth(<MyPreferences />)} /> */}
          <Route
         exact
         path="/watchlater"
         element={
           <ProtectedRoute>
             <MyList />
           </ProtectedRoute>
         }
       />
         
          {/* <Route path="/watchlater" element={requireAuth(<MyList />)} /> */}
          <Route path="/otp" element={requireAuth(<OtpVerification />)} />
          {/* <Route
            path="/success"
            element={requireAuth(<Successpage />)}
          /> */}

          <Route path="/success" element={<Successpage />} />
          <Route path="/verifyfailed" element={requireAuth(<VerifyFailed />)} />
          <Route
         exact
         path="/activatetv"
         element={
           <ProtectedRoute>
             <ActiveTv />
           </ProtectedRoute>
         }
       />
          {/* <Route path="/activatetv" element={requireAuth(<ActiveTv />)} /> */}

          <Route
            exact
            path={"/movies/telugu-movies/:genere"}
            element={<MorePage />}
          />

          <Route
            exact
            path={"/channels/:genere"}
            element={<MoreDetailsPage />}
          />

          <Route
            exact
            path={"/recipes/:seourl"}
            element={<RecipesMoreDetails />}
          />

          {/* <Route path="/recipes" element={<LazyLoadRecipes />} /> */}

          {/* <Route
  exact
  path="/:category/?:subcategory?/:main?/:seo_url"
  element={<VideoDetails />}
/> */}

          {/* <Route
            exact
            path={"/:movie-name/:movie-genere"}
            element={<MoreDetails />}
          /> */}

          <Route
            exact
            path={"/:category/:seo_url"}
            element={<VideoDetails />}
          />

          <Route
            path={"/:category/:seo_url/:seo_url_friendly"}
            element={<ShowVideoView />}
          />
          {/* <Route path={"/:telugu-movies/:seo_url/:seo_url_friendly"} element={<ShowVideoView />} /> */}

          {/* <Route path={"/:category/:seo_url/:main/:seo_url_friendly"} element={<VideoDetails /> } /> */}

          <Route
            path={"/:category/:seo_url/:main/:seo_url_friendly"}
            element={<ShowVideoView />}
          />

          <Route
            path={"/:category/:seo_url/:main/:seo_url_friendly/:fifth_url"}
            element={<ShowVideoView />}
          />

          {/* <Route
            exact
            path={"/:category/:friendly_id"}
            element={<VideoDetails />}
          /> */}
          {/* 
          <Route
            exact
            path={
              "/:category/:subcategory/:main/:seo_url"
                ? "/:category/:subcategory/:main/:seo_url"
                : "/:category/:seo_url"
            }
            element={<VideoDetails />}
          /> */}
          {/* <Route
            exact
            path={"/:category/:subcategory/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:category/:subcategory/:main/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:category/:friendly_id"}
            element={<MoreDetails />}
          /> */}

          <Route exact path="/:pageName" element={<MoreDetails />} />
          {/* <Route exact path="/profileselector" element={<ProfileSelector />} /> */}
          <Route
          exact
          path="/profiles"
          element={
            <ProtectedRoute>
              <ProfileSelector />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/updateprofile"
          element={
            <ProtectedRoute>
              <NewProfile />
            </ProtectedRoute>
          }
        />
          {/* <Route exact path="/updateprofile" element={<NewProfile />} /> */}
          <Route
          exact
          path="/manageprofile"
          element={
            <ProtectedRoute>
              <ManageProfiles />
            </ProtectedRoute>
          }
          />
          {/* <Route exact path="/manageprofile" element={<ManageProfiles />} /> */}
          {/* <Route exact path="/edit-profile/:id" element={<EditProfile />} /> */}
          <Route
          exact
          path="/edit-profile/:id"
          element={
            <ProtectedRoute>
              <NewProfile />
            </ProtectedRoute>
          }
        />
          <Route
            exact
            path={"/:movie-name/:movie-genere"}
            element={<MoreDetails />}
          />
          <Route exact path="home/:pageName" element={<MoreDetails />} />
          <Route
            exact
            path="home/:pageName/:channelID"
            element={<MoreDetails />}
          />
          <Route
            exact
            path="more/:pageName/:categoryItem"
            element={<MoreDetails />}
          />
          <Route
            exact
            path="more/recipes/:pageName"
            element={<MoreDetails />}
          />
          <Route exact path="recipes/:pageName" element={<MoreDetails />} />
          <Route
            exact
            path="/:pageName/:pageName2/all"
            element={<EpisodeMore />}
          />
          <Route exact path="/more/:pageName2/all" element={<EpisodeMore />} />

          <Route
            exact
            path="/more/:pageName/:pageName2/:categoryItem"
            element={<MoreDetails />}
          />
          {/* 
     
      <Route
      exact
      path="/channels/etv-telugu/:categoryItem"
      element={<MoreDetails />}
      />
      <Route
      exact
      path="/channels/etv-plus/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-abhiruchi/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-andhra-pradesh/:categoryItem"
      element={<MoreDetails />}
      />
        <Route
      exact
      path="/channels/etv-telangana/:categoryItem"
      element={<MoreDetails />}
      />
       <Route
      exact
      path="/channels/win-exclusive/:categoryItem"
      element={<MoreDetails />}
      /> */}
          <Route
            exact
            path="more/catalog_lists/:pageName"
            element={<MoreDetails />}
          />

          {/*
                <Route
            exact
            path="channels/:pageName"
            element={<MoreDetails />}
            /> */}
          {/* <Route
            exact
            path="recipes/:pageName"
            element={<MoreDetails />}
            /> */}
          {/* <Route
    exact
    path="/:pageName/:friendly_id"
    element={<MoreDetails />}
  /> */}

          {/* <Route
    exact
    path="/:friendly_id/:moreseo_url/:main"
    element={<MoreDetails />}
  /> */}

          {/* <Route
            exact
            path={"/:main/:submain/:category/:subcategory/:seo_url"}
            element={<VideoDetails />}
          /> */}

          {/* <Route
            exact
            path={"/:channel/:channelname/:category/:subcategory/:seo_url"}
            element={<NewsDetails />}
          /> */}

<Route
          exact
          path="/paymentstatus"
          element={
              <PaymentStatus />
          }
        />


        

          {/* <Route exact path={"/paymentstatus"} element={<PaymentStatus />} /> */}
          <Route exact path={"/confirmation"} element={<EmailVerified />} />
          {isValidkey === "The link is valid" && (
         <Route
         exact
         path="/reset_password/"
         element={
            <ResetPassword />
        }
       />
        //  <Route exact path="/reset_password" element={<ResetPassword />} />
        )}
        </Routes>
      </Suspense>

      {(keyerror || keyerror === "Link is expired or Invalid link") && (
        <Modal
          show={showfailedmessage }
          onHide={handleerrorModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
          <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <img src={verificationfailed} alt="" /> 
                  <div className="successPopup">
                    <img src={resetpassword} alt="" /> 
                    <p>Password Reset failed due to Link has expired, Please try again later.</p>
                  </div>
                  
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Routers;
