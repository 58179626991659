import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import InputField from "../../Componets/inputfield";
import axios from "axios";
// import { getAnalytics,logEvent  } from 'firebase/analytics';


import {
  SIGNUP_OTP_URL,
  USERS_ENDPOINT,
  auth_token,
} from "../../services/config";
import OtpVerification from "../otpverify/signupmobileverify";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setEmailSignupData, setRegisterDetails } from "../../Redux/Slice/signupDetailsSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";
import { setModalClose } from "../../Redux/Slice/loginModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";
import EmailValidation from "../emailValid/emailValidation";

const RegisterByMobile = ({
  handleLogin,
  showOTPPopup,
  setShowOTPPopup,
  handleOtpPopupClose,
}) => {
  const logo = "/images/win-logo.png";
  const LoginPoster = "/images/loginposter.jpg";
  const OtherRegionLoginPoster = "/images/WEBLOGINOther.jpg";
  const getaccessToken = useSelector((state) => state.accesstoken_data.accessTokenData);
  const guestuserid = `Guest ${Math.floor(2e4 * Math.random())}`;
  const regionsData = useSelector(state => state.region.regionsData)
  const [formData, setFormData] = useState({
    firstname: "",
    user_id: "",
    agreeTerms: false,
  });


  const initialFormData = {
    firstname: "",
    email_id: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
  };

    const [emailformData, setEmailFormData] = useState(initialFormData);
  

  const loginStatus = localStorage.getItem("login_status")
  const getuserid = localStorage.getItem("user_id")
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch()
  const [formValid, setFormValid] = useState(false);

  const [emailFormValid, setEmailFormValid] = useState(false);

  const [formDataErrors, setFormDataErrors] = useState({
    firstname: "",
    user_id: "",
    agreeTerms: false,
  });

    const [emailformDataErrors, setEmailFormDataErrors] = useState({
      firstname: "",
      email_id: "",
      password: "",
      confirmPassword: "",
      agreeTerms: false,
    });

  const [loading, setLoading] = useState(false);

  // const firebaseAnalytics = getAnalytics();
 
  const validateForm = () => {
    const errors = {};
    let formIsValid = true;
  
    if (!formData.firstname) {
      errors.firstname = "Please enter firstname";
      formIsValid = false;
    }
  
    if (!formData.user_id) {
      errors.user_id = "Please enter mobile number";
      formIsValid = false;
    }
  
    setFormDataErrors(errors);
  
    return formIsValid;
  };

  const handleEmailRegister = () => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
    dispatch(setModalClose())
    dispatch(setShowRegisterModal({ showRegisterModal: true }))
  };
  
  
      const activeCountryCode =
        regionsData?.country_code2 !== "IN" ? "email" : "mobile"; 
      const [activeTab, setActiveTab] = useState(
        regionsData?.country_code2 !== "IN" ? "email" : "mobile"
      );
    
      useEffect(() => {
        setActiveTab(regionsData?.country_code2 !== "IN" ? "email" : "mobile");
      }, [activeCountryCode]);
      
      // const firebaseAnalytics = getAnalytics();
    
    
      const handleTabChange = (tab) => {
        setActiveTab(tab);
        setFormData({
          user_id: "",
          password: "",
        });
        setFormDataErrors({
          user_id: "",
          password: "",
        });
      };
  
      // console.log("activeTabsj", activeTab)
  

       const handleMobileLogin = () => {
          dispatch(setModalClose())
          dispatch(setShowRegisterModal({ showRegisterModal: false }))
          dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: true }))
        };


        const handleTogglePassword = () => {
          setShowPassword(!showPassword);
        };



  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));


    setFormValid(validateForm());
  };



  const handleEmailInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setEmailFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      if (name === "firstname") {
        if (!/^[A-Za-z. ]+$/.test(value)) {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "Invalid input",
          }));
          setEmailFormValid(false);
        } else {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "",
          }));
          setEmailFormValid(true);
        }
      }

      if (name === "email_id") {
        if (!isValidEmail(value)) {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            email_id: "Invalid email address",
          }));
          setEmailFormValid(false);
        } else {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            email_id: "",
          }));
          setEmailFormValid(true);
        }
      }

      if (name === "password") {
        setEmailFormDataErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));

        if (!isValidPassword(value)) {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password:
              "Password must be 8 characters or longer and include at least one number or symbol.",
          }));
          setEmailFormValid(false);
        } else {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
          setEmailFormValid(true);
        }
      }

      if (name === "confirmPassword") {
        if (value !== emailformData.password) {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "Password and confirm password do not match",
          }));
          setEmailFormValid(false);
        } else {
          setEmailFormDataErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: "",
          }));
          setEmailFormValid(true);
        }
      }

      setEmailFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formIsValid = validateForm();

    if (!formIsValid) {
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(
        SIGNUP_OTP_URL,
        {
          auth_token: auth_token,
          access_token: getaccessToken?.access_token,
          user: {
            user_id: `0091${formData.user_id}`,
            mobile_number: `0091${formData.user_id}`,
            firstname: formData.firstname,
            region: regionsData?.country_code2,
            type: "msisdn",
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success?.status === 200) {
        // console.log("jsjksks", response.data)
        dispatch(setModalClose())
        dispatch(setRegisterDetails({registerDetails: response?.data?.success}))
        setFormValid(true);
        toast.success(response?.data?.success?.message);
      
        setShowOTPPopup(true);
      } else {
        setShowOTPPopup(false);
      }

      setFormData({
        firstname: "",
        user_id: "",
        agreeTerms: false,
      });
    } catch (error) {
      if (error.response) {
        if (error?.response?.status === 422) {
          toast.error(error.response.data.error.message);
 
        }
        setShowOTPPopup(false);
        setFormValid(false);
        console.error(
          "Server responded with an error:",
          error.response
          // error.response.data.error.message
        );
      }

      if (error?.response?.status === 422) {
        if (window && window.sdk && window.sdk.trackEvent) {
          window.sdk.trackEvent("signup_failure", {
            error_type: error?.response?.data?.error?.message,
            method: "phone number",
            event_id: 4,
            platform:"web",
            device_id: "web",
            city: regionsData?.city_name,
            state: regionsData?.state,
            ip: regionsData?.ip,
            postal_code: regionsData?.postal_code,
            u_id:
            loginStatus === "true"
            ? getuserid
            : guestuserid,
          });
      
        } else {
          console.error('Analytics SDK is not initialized yet.');
          // Handle initialization state or queue events for later
        }

       
        // logEvent(firebaseAnalytics, 'signup_failure', {
        //   error_type: error?.response?.data?.error?.message,
        //   method: "phone number",
        //   device_id: 'NA'
        // });
     

        if (
          error?.response?.data?.error?.message === "User already registered."
        ) {
          setShowOTPPopup(false);
          setFormValid(false);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
        if (error?.response?.data?.error?.message === "Invalid number") {
          setShowOTPPopup(false);
          setFormValid(false);
          setFormDataErrors({
            ...formDataErrors,
            ["user_id"]: error?.response?.data?.error?.message,
          });
        }
      }
    }finally {
      setLoading(false); 
    }
  };
  const [showEmailValidpopup, setShowEmailValidpopup] = useState(false);

  const handleEmailPopupClose = () => {
    setShowEmailValidpopup(false);
             dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
          dispatch(setModalClose());
          dispatch(setShowRegisterModal({ showRegisterModal: false }))
  };

  function isValidEmail (email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return regex.test(email)
};

  function isValidPassword(password) {
    const regex =
      /^(?=.*[a-zA-z])(?=.*[$@!%*^#&\d])(?!.*[\^]{8,})(?!.*[_])[\w@$!%*^#&]{8,}$/;

    return regex.test(password);
  }

    const handleEmailSubmit = async (e) => {
      e.preventDefault();
  
      const errors = {};
  
      if (!emailformData.firstname) {
        errors.firstname = "Please select firstname";
      }
  
      if (!emailformData.email_id) {
        errors.email_id = "Please select email";
      }
  
      if (emailformData.password !== emailformData.confirmPassword) {
        setEmailFormDataErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Password and confirm password do not match",
        }));
        setEmailFormValid(false);
        return;
      } else {
        setEmailFormDataErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "", // Clear the error when passwords match
        }));
        setEmailFormValid(true);
      }
  
      if (!isValidPassword(emailformData.password)) {
        setEmailFormDataErrors((prevErrors) => ({
          ...prevErrors,
          password:
            "Password must be 8 characters or longer and include at least one number or symbol.",
        }));
        setEmailFormValid(false);
        return;
      }
  
      if (Object.keys(errors).length > 0 || !emailformData.agreeTerms) {
        setEmailFormDataErrors(errors);
        setEmailFormValid(false);
        return;
      } else {
        setEmailFormValid(true);
      }
      setLoading(true)
      try {
        const response = await axios.post(
          USERS_ENDPOINT,
          {
            auth_token: auth_token,
            access_token: getaccessToken?.access_token,
            user: {
              email_id: emailformData.email_id,
              firstname: emailformData.firstname,
              password: emailformData.password,
              region: regionsData?.country_code2,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response?.status === 200) {
         
          setEmailFormValid(true);
          dispatch(setModalClose());
          dispatch(setShowRegisterModal({ showRegisterModal: false }))
          dispatch(setEmailSignupData({ emailSignupData: response?.data?.data }));
          setShowEmailValidpopup(true);
        } else {
          dispatch(setShowRegisterModal({ showRegisterModal: false }))
        }
  
        setEmailFormData(initialFormData);
      } catch (error) {
        if (error.response) {
          console.error("Server responded with an error:", error.response);
        }
  
        if (error?.response?.status === 422) {
          toast.error(error?.response?.data?.error?.message);
          setEmailFormDataErrors({
              ...emailformDataErrors,
              ["email_id"]: error?.response?.data?.error?.message,
            });
            setShowEmailValidpopup(false);
  
            if (window && window.sdk && window.sdk.trackEvent) {
              window.sdk.trackEvent("signup_failure", {
                error_type: error?.response?.data?.error?.message,
                method: "email id",
                event_id: 4,
                u_id:
                loginStatus === "true"
                ? getuserid
                : guestuserid,
                platform:"web",
                device_id: "web",
                city: regionsData?.city_name,
                state: regionsData?.state,
                ip: regionsData?.ip,
                postal_code: regionsData?.postal_code,
              });
          
            } else {
              console.error('Analytics SDK is not initialized yet.');
              // Handle initialization state or queue events for later
            }
        
          // logEvent(firebaseAnalytics, "signup_failure", {
          //   method: "email id",
          //   error_type: error?.response?.data?.error?.message,
          //   device_id: "NA",
          // });
          //   firebaseAnalytics.logEvent('signup_failure', {
          //     error_type: error?.response?.data?.error?.message,
          //     method: "email id",
          //     device_id: 'NA'
          // });
          // if (
          //   error?.response?.data?.error?.message === "User is already verified"
          // ) {
          //   toast.error(error?.response?.data?.error?.message);
          //   setFormDataErrors({
          //     ...formDataErrors,
          //     ["email_id"]: error?.response?.data?.error?.message,
          //   });
          //   setShowEmailValidpopup(false);
          // }
          // if (
          //   error?.response?.data?.error?.message ===
          //   "Email Id already registered"
          // ) {
          //   toast.error(error?.response?.data?.error?.message);
          //   setFormDataErrors({
          //     ...formDataErrors,
          //     ["email_id"]: error?.response?.data?.error?.message,
          //   });
          //   setShowEmailValidpopup(false);
          // }
        }
      }finally {
        setLoading(false); 
      }
    };

  const handleMobileRegisterClose = () => {
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
    dispatch(setModalClose())
    setFormData({
      firstname: "",
      user_id: "",
      password: "",
    });

    setFormDataErrors({
      firstname: "",
      user_id: "",
      password: "",
    });
  };


  const handleMobileInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === "user_id") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      const limitedValue = numericValue.slice(0, 10); // Limit to 10 digits
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: limitedValue,
      }));
  
      const errors = {};
  
      if (!/^\d+$/.test(value)) {
        // Check if there are non-numeric characters
        errors.user_id = "Mobile number should contain only numbers";
      } else if (limitedValue.length !== 10) {
        errors.user_id = "Mobile number must be 10 digits";
      } else {
        errors.user_id = "";
      }
  
      setFormDataErrors(errors);
  
      const isFormValid = Object.values(errors).every((error) => !error);
      setFormValid(isFormValid);
    } else {
      // For other fields, simply update the form data
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  
  const { showMobileRegisterModal } = useSelector(
    (state) => state.mobileregisterModal
  );

  const userConfig = useSelector((state) => state.user_config.userConfigData);

  return (
    <>

       





{showOTPPopup ? (
        <OtpVerification
          setShowOTPPopup={setShowOTPPopup}
          formData={formData}
          showOTPPopup={showOTPPopup}
          handleOtpPopupClose={handleOtpPopupClose}
        />
      ) : (
        <>
 {showEmailValidpopup ? (
            <EmailValidation
              setShowEmailValidpopup={setShowEmailValidpopup}
              showEmailValidpopup={showEmailValidpopup}
              handleEmailPopupClose={handleEmailPopupClose}
            />
          ) : (
            <>
          {showMobileRegisterModal && (
            <>
              <Modal
                show={showMobileRegisterModal}
                onHide={handleMobileRegisterClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                className="border-class"
              >
                <Modal.Body>
                  <div className="card-deck">
                    <Row className="mb-2">
                      <Col lg="12">
                        <div className="login-logo d-flex justify-content-between align-items-center">
                          <div className="">
                                {/* <img src={logo} /> */}
                          </div>

                          <div className="">
                            <Link onClick={handleMobileRegisterClose}><FaTimes className="fa-lg"/></Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 padding-rt-40">
                      <div className="d-none d-lg-block">
                      <img src={regionsData?.country_code2 === "IN" ? LoginPoster : OtherRegionLoginPoster} className="w-100 rounded" loading="lazy" alt="Login Poster" /> 
                        </div>
                        <div className="login-left-text d-none">
                          <ul>
                            <li>
                              <p>Lorem Ipsum is simply</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum ***</p>
                            </li>
                            <li>
                              <p>Lorem Ipsum is simply dummy text</p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                            <li>
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                      <div className="login-left-styles">
                          <h6 className="text-center">Register Now</h6>
                          <p className="text-center">Enjoy ETV Win with More Benefits and Features </p>
                        </div>
                        {regionsData?.country_code2 === "IN" && (
                            <div className="loginTabs">
                              <button
                                className={`btn ${
                                  activeTab === "mobile" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("mobile")}
                              >
                                Mobile Number
                              </button>
                              <button
                                className={`btn ${
                                  activeTab === "email" ? "active" : ""
                                }`}
                                onClick={() => handleTabChange("email")}
                              >
                                Email
                              </button>
                            </div>
                          )}






{activeTab === "email" &&
                              <div className="form-style register">
                                                      <form onSubmit={handleEmailSubmit}>
                                                        <InputField
                                                          type="text"
                                                          name="firstname"
                                                          value={emailformData.firstname}
                                                          onChange={handleEmailInputChange}
                                                          placeholder="Full Name *"
                                                          // autoComplete="off"
                                                          // error={formDataErrors.firstname}
                                                        />
                            
                                                        <InputField
                                                          type="email"
                                                          name="email_id"
                                                          value={emailformData.email_id}
                                                          // autoComplete="off"
                                                          onChange={handleEmailInputChange}
                                                          placeholder="Enter Email ID*"
                                                          error={emailformDataErrors.email_id}
                                                        />
                            
                                                        <InputField
                                                          type="password"
                                                          handleTogglePassword={handleTogglePassword}
                                                          showPassword={showPassword}
                                                          name="password"
                                                          autoComplete="off"
                                                          value={emailformData.password}
                                                          onChange={handleEmailInputChange}
                                                          placeholder="Password*"
                                                          error={emailformDataErrors.password}
                                                        />
                            
                                                        <InputField
                                                          type={showPassword ? "text" : "password"}
                                                          autoComplete="off"
                                                          name="confirmPassword"
                                                          value={emailformData.confirmPassword}
                                                          onChange={handleEmailInputChange}
                                                          placeholder="Confirm Password*"
                                                          error={emailformDataErrors.confirmPassword}
                                                        />
                                                        <InputField
                                                          className=""
                                                          type="checkbox"
                                                          name="agreeTerms"
                                                          checked={emailformData.agreeTerms}
                                                          onChange={handleEmailInputChange}
                                                          checkboxLabel="I agree to the <Link to='/termsandconditions'>TERMS OF USE</Link> and <Link to='/privacypolicy'>PRIVACY POLICY</Link>"
                                                        />
                                                        <button
                                                          disabled={!emailFormValid || !emailformData.agreeTerms}
                                                          className="next-button-color"
                                                        >
                                                          {loading ? <Spinner /> : 'Next'}
                                                        </button>
                                                      </form>
                                                      <div className="registe-color">
                                                        {/* {regionsData?.country_code2 === "IN" && (
                                                          <div className="register-left">
                                                            <p>
                                                              Register via &nbsp;
                                                              <Link onClick={handleMobileLogin}>
                                                                Mobile Number
                                                              </Link>
                                                            </p>
                                                          </div>
                                                        )} */}


                                                        

                            
                                                        <div className="register-right">
                                                          <p>
                                                            Already a Member?
                                                            <Link onClick={handleLogin}> Login</Link>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    
                          }


                        {activeTab === "mobile" && <div className="form-style register">
                          <form onSubmit={handleSubmit}>
                            <InputField
                              type="text"
                              name="firstname"
                              value={formData.firstname}
                              onChange={handleInputChange}
                              placeholder="Full Name*"
                              autocomplete="off"
                            />
                            <InputField
                              type="tel"
                              name="user_id"
                              value={formData.user_id}
                              onChange={handleMobileInputChange}
                              placeholder="Mobile Number*"
                              autocomplete="off"
                              error={formDataErrors.user_id}
                            />

                            <InputField
                              type="checkbox"
                              name="agreeTerms"
                              checked={formData.agreeTerms}
                              onChange={handleInputChange}
                              checkboxLabel="I agree to the <Link to='/termsandconditions'>TERMS OF USE</Link> and <Link className='privacypolicy' to='/privacypolicy'>PRIVACY POLICY</Link>"
                              // checkboxLabel="I agree to the TERMS OF USE and PRIVACY POLICY"
                            />

                            <button disabled={!formValid || !formData.agreeTerms} className="next-button-color"> {loading ? <Spinner /> : 'Next'}</button>
                          </form>


                         
                          <div className="registe-color">
                            <div className="register-left">
                              {/* <p>
                                Register via
                                <Link onClick={handleEmailRegister}>
                                  &nbsp; Email ID
                                </Link>
                              </p> */}
                            </div>
                            <div className="register-right">
                              <p>
                                Already a Member?
                                <Link onClick={handleLogin}> Login</Link>
                              </p>
                            </div>
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
            </>
          ) }
        
        </>
      )}
     
    </>
  );
};

export default RegisterByMobile;
