import React, { useEffect, useRef } from "react";
import Header from "../../Componets/Header/Header";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Footer from "../../Componets/Footer/Footer";
import { Link } from "react-router-dom";
import InputField from "../../Componets/inputfield";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, auth_token } from "../../services/config";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { allCountries } from "./countries";

export default function ContactUs() {
  const contactimg = "/images/contactus.png";
  const contactsuccessimg = "/images/icn_successfull.png";
  const successgif = "/images/success.gif";
  const getaccessToken = useSelector(
    (state) => state.accesstoken_data.accessTokenData
  );

  const regionsData = useSelector((state) => state.region.regionsData);

  const [contactmodal, setContactmodal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleFeebackModal = () => {
    setContactmodal(true);
  };

  const handleFeebackModalClose = () => {
    setContactmodal(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitLoad, setFormSubmitLoad] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    mobile_number: "",
    issue_type: "",
    file: "",
    message: "",
    country_code: "",
    access_token: getaccessToken?.access_token,
    auth_token: auth_token,
    region: regionsData?.country_code2,
    captcha_token: captchaToken,
  });
  const [error, setError] = useState("");
  const [recaptchaError, setRecaptchaError] = useState("");
  const [usererror, setUserError] = useState("");
  const [useremailerror, setUserEmail] = useState("");
  const [usermblerror, setUserMblnum] = useState("");
  const [userissuetype, setUserissue_type] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState(""); // New state for selected country Code

  const uniqueCountries = Array.from(
    new Map(allCountries.map((country) => [country.Code, country])).values()
  );
  
  const handleCountryChange = (event) => {
    const selectedCode = event.target.value; // e.g., "IN" for India
    setSelectedCountryCode(selectedCode); // Update the selected country Code

    // Find the selected country object
    const selectedCountry = allCountries.find(
      (country) => country.Code === selectedCode
    );

    if (selectedCountry) {
      const mobileCode = selectedCountry.MobileCode.replace(/\+/g, ""); // e.g., "91"
      setFormData((prevState) => ({
        ...prevState,
        country_code: mobileCode, // Store the mobile code (e.g., "91")
        mobile_number: `+${mobileCode}`, // Set the mobile number with country code (e.g., "+91")
      }));
    }
  };

  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const issueTypeRef = useRef(null);
  const messageRef = useRef(null);
  const usernameRef = useRef(null);
  const handleKeyPress = (event) => {
    const charCode = event.charCode;

    if (!/[a-zA-Z]/.test(String.fromCharCode(charCode))) {
      event.preventDefault();
    }
  };

  const [recaptchaToken, setRecaptchaToken] = useState("");

  const onCaptchaChange = (token) => {
    if (token) {
      setRecaptchaToken(token);
    } else {
      setRecaptchaError("Please complete the CAPTCHA verification.");
    }
  };

  const handleMobileNumberInput = (event) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    setFormData((prevState) => ({
        ...prevState,
        mobile_number: inputValue, // Store only the number (without country code)
    }));
};

  // console.log(error, "------->error");

  const onFileChange = (event) => {
    let supportedFiles = ["image/png", "image/jpeg", "image/gif"];
    let fileData = event.target.files[0];
    // console.log(fileData, "event from On Change");
    if (event.target.files && event.target.files.length > 0) {
      if (!supportedFiles.includes(fileData.type)) {
        setFileUploadError("File Not Supported");
        setFormData({ ...formData, file: "" });
        // console.log(fileData.type, "fileData-Type");
        return;
      } else if (fileData.size > 1250000) {
        // console.log("Size Larger than 1 mb");
        setFileUploadError("Size Larger than 1 MB");
        setFormData({ ...formData, file: "" });
        return;
      } else {
        let reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
          let file = event.target.files[0];
          reader.readAsDataURL(file);
          reader.onload = () => {
            const blobData =
              "data:" + file.type + ";base64," + reader.result.split(",")[1];
            // console.log(blobData, "blobData");
            setFormData({ ...formData, file: blobData });
            setFileUploadError("");
          };
        }
      }
    } else {
      setFileUploadError("");
    }
  };

  const updatedformData = {
    ...formData,
    access_token: getaccessToken?.access_token,
    auth_token: auth_token,
    region: regionsData?.country_code2,
    captcha_token: captchaToken,
  };

  // console.log(updatedformData, "updatedformData");

  //   function isValidEmail (email) {
  //     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //     return regex.test(email) && email.endsWith('@gmail.com');
  // };

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailPattern.test(email);
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile =
    (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) &&
    !window.MSStream;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const checkFormValidation = (data) => {
    setUserError("");
    setUserEmail("");
    setUserMblnum("");
    setUserissue_type("");
    setError("");

    if (!data.user_name) {
      setUserError("*Please Enter Name");
      return { valid: false, ref: "user_name" };
    } else if (!data.user_email) {
      setUserEmail("*Please Enter Email ID");
      return { valid: false, ref: "user_email" };
    } else if (data.user_email && !isValidEmail(data.user_email)) {
      setUserEmail("*Please Enter a Valid Email");
      return { valid: false, ref: "user_email" };
    } else if (!data.mobile_number) {
      setUserMblnum("*Please Enter Mobile Number");
      return { valid: false, ref: "mobile_number" };
    }  else if (data.mobile_number.replace(/^\+\d+/, "").length < 10) {  
      setUserMblnum("Enter a Valid Mobile Number (without country code)");
      return { valid: false, ref: "mobile_number" };
  } else if (!data.issue_type) {
      setUserissue_type("*Please Select the Issue");
      return { valid: false, ref: "issue_type" };
    } else if (!data.message) {
      setError("*Please Enter Message");
      return { valid: false, ref: "message" };
    } else {
      return { valid: true };
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const validation = checkFormValidation(formData);
    if (!validation.valid) {
      focusOnErrorField(validation.ref);
      setLoading(false);
      return;
    }

    try {
      const contactDetailsUpdateUrl = `${BASE_URL}users/contact`;
      const response = await axios.post(
        contactDetailsUpdateUrl,
        {
          auth_token,
          access_token: getaccessToken?.access_token,
          region: regionsData?.country_code2,
          // captcha_token: captchaToken,
          user_name: formData.user_name,
          user_email: formData.user_email,
          mobile_number: formData.mobile_number,
          issue_type: formData.issue_type,
          file: formData.file,
          message: formData.message,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSuccessMessage(response?.data?.message);
        setFormData({
          user_name: "",
          user_email: "",
          mobile_number: "",
          issue_type: "",
          file: "",
          message: "",
        });
      }

      setContactmodal(true);
      // console.log("Contact Us Form Submitted Successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Some Error Occurred, Please Try Again.");
      setContactmodal(false);
    } finally {
      setLoading(false);
    }
  };

  const focusOnErrorField = (refName) => {
    if (refName === "user_name" && usernameRef.current) {
      usernameRef.current.focus();
    } else if (refName === "user_email" && emailRef.current) {
      emailRef.current.focus();
    } else if (refName === "mobile_number" && mobileRef.current) {
      mobileRef.current.focus();
    } else if (refName === "issue_type" && issueTypeRef.current) {
      issueTypeRef.current.focus();
    } else if (refName === "message" && messageRef.current) {
      messageRef.current.focus();
    }
  };

  return (
    <>
      {/* <Header /> */}
      <div className="main ">
        <Container>
          <h4>Contact Us </h4>
          <div className="about-main">
            <Row>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>
                <div className="etv-data">
                  <img src={contactimg} alt="contactimage" />
                  <h6 className="etv-heding-style">ETV Win</h6>
                  <p className="p-heding-style">
                    ETV Building, 2nd Floor Ramoji Film City <br /> Hyderabad -
                    501512 - Telangana - India
                  </p>
                  <div className="phone-number-style">
                    <p>
                      Phone:
                      <Link to="tel:+91 8142212345">
                        {" "}
                        +91 8142212345, &nbsp;{" "}
                      </Link>
                      <Link to="tel:+91 8008020555">+91 8008020555</Link>
                    </p>
                  </div>
                  <div className="emailadres-style">
                    <p>Mon-Sun 7 AM - 10 PM (IST)</p>
                    Email :{" "}
                    <Link to="mailto:contact@etvwin.com">
                      contact@etvwin.com
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={6} style={{ display: "flex", alignItems: "center" }}>
                <div className="form-styling-new content-profiles border-styw">
                  <form onSubmit={handleFormSubmit}>
                    <div className="form-group lable-space">
                      <label for="checkbox1">Name</label>
                      <input
                        type="text"
                        ref={usernameRef}
                        name="user_name"
                        value={formData.user_name}
                        placeholder="Enter Name"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                      {usererror && (
                        <p style={{ color: "yellow" }}>{usererror}</p>
                      )}
                    </div>
                    <div className="form-group lable-space">
                      <label for="checkbox1">Email</label>
                      <input
                        type="email"
                        placeholder="Enter Email ID"
                        name="user_email"
                        ref={emailRef}
                        value={formData.user_email}
                        // autocomplete="off"
                        onChange={handleChange}
                      />
                      {useremailerror && (
                        <p style={{ color: "yellow" }}>{useremailerror}</p>
                      )}
                    </div>
                    <div className="form-group lable-space">
                      <label for="checkbox1">Select Your Country</label>
                      <select
                        name="country"
                        className="custom-country-dropdown"
                        value={selectedCountryCode} 
                        onChange={handleCountryChange}
                        required
                      >
                       
                        {uniqueCountries.map((country) => (
    <option key={country.Code} value={country.Code}>
      {country.Name} ({country.Code})
    </option>
  ))}
                      </select>
                    </div>
                    <div className="form-group lable-space">
                      <label for="checkbox1">Phone Number</label>
                      <input
                        type="tel"
                        name="mobile_number"
                        placeholder="Enter Your Mobile Number"
                        autocomplete="off"
                        maxLength={15}
                        ref={mobileRef}
                        value={formData.mobile_number}
                        pattern="[0-9]*"
                        onChange={handleMobileNumberInput}
                      />
                      {usermblerror && (
                        <p style={{ color: "yellow" }}>{usermblerror}</p>
                      )}
                    </div>

                    <div className="form-group country-phone-wrapper">
                      {/* Country Dropdown */}
                      <div className="country-select"></div>
                    </div>

                    <div className="form-group lable-space">
                      <label for="checkbox1">Select Your issue</label>
                      <select
                        name="issue_type"
                        tabindex="-1"
                        ref={issueTypeRef}
                        placeholder="Select your issue"
                        value={formData.issue_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Your issue</option>
                        <option value="Payment Issue">Payment Issue</option>
                        <option value="Subscription Issue">
                          Subscription Issue
                        </option>
                        <option value="Other Issue">Other Issue</option>
                      </select>
                      {userissuetype && (
                        <p style={{ color: "yellow" }}>{userissuetype}</p>
                      )}
                    </div>

                    <div className="form-grou">
                      <label for="checkbox1">Message</label>
                      <textarea
                        ref={messageRef}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>

                      {error && <p style={{ color: "yellow" }}>{error}</p>}
                    </div>

                    <div className="form-group lable-space uploadFile">
                      <label for="checkbox1">Upload Image</label>

                      <input
                        type="file"
                        name="file"
                        autocomplete="off"
                        onChange={onFileChange}
                        style={{ padding: "9px" }}
                      />
                      <small>
                        {" "}
                        Accepted extension .png, .jpg, .jpeg, .gif and upto 1 mb
                      </small>
                      {fileUploadError && (
                        <p style={{ color: "yellow" }}>*{fileUploadError}</p>
                      )}
                    </div>

                    <div className="form-group">
                      <ReCAPTCHA
                        sitekey="6LfrMzMnAAAAAMutq7oLBBueX6Z4DlGMxQLDwNp0"
                        onChange={onCaptchaChange}
                      />
                      {recaptchaError && (
                        <p style={{ color: "red" }}>{setRecaptchaError}</p>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="next-button-color"
                      disabled={loading || !recaptchaToken}
                    >
                      {loading ? <Spinner /> : "Submit"}
                    </button>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Footer />
      </div>
      {contactmodal && (
        <Modal
          show={contactmodal}
          onHide={handleFeebackModalClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size=""
          className="border-class"
        >
          <Modal.Body>
            <div className="card-deck">
              <Row className="justify-content-center">
                <Col lg={12} md={12} sm={12}>
                  <div className="successPopup">
                    <img src={successgif} alt="" />
                    {/* <h6>Thank You!</h6> */}
                    <p>{successMessage}</p>
                    {!isMobile && (
                      <Link to="/home" className="backToHome">
                        Back to Home
                      </Link>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
